.date-picker-otde {
    width: 380px;
    height: 45px;
    border-radius: 19px;
    color: black;
    font-size: 15px;
    font-weight: bolder;
    position: absolute;
    right: 5px;
    top: -10px;
    border: 1px solid;
    padding-left: 10px;
}
