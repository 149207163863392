@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

.app-hero {
  background-image: linear-gradient(
                  to bottom,
                  rgba(24, 206, 15, 0.8),
                  rgba(0, 0, 0, 0.5)
  ),
  url("../../images/bg-home.jpg");

  .logo-map {
    max-width: 200px;
    margin-bottom: 80px;
  }
}

.dropdown-none {
  display: none;
}

.dropdown-block {
  display: block;
}
